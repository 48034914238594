:root {
    --primary-color: rgb(135, 24, 74)
}

.chat_thread_list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    height: 100vh;
    overflow-y: scroll;
}

.chat_thread_list li {
    padding: 10px;
}

.btn-icons {
    cursor: pointer;
}

.grid_header {
    background-color: var(--primary-color);
    color: white;
    padding: 15px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.rna-container .alert {
    border-radius: 4px; }
    .rna-container .alert button.close {
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center; }
      .rna-container .alert button.close span {
        margin-top: -4px; }

.userCard {
    margin-bottom: 5px;
}

.btn-icons i {
    color: var(--primary-color);
}

.btn-white i {
    color: white;
}

.unread_chat {
    background-color: color(srgb 0.705 0.0811 0.3676);
    color: white;
    border-bottom: solid 1px var(--primary-color);
}

.read_chat {
    background-color: white;
    border-bottom: solid 1px #ececec;
}

.onlineTab {
    background-color: rgb(7, 208, 74);
    color: white;
}

.onlineTab.active {
    background-color: rgb(207, 255, 187)!important;
}

.awayTab {
    background-color: rgb(211, 123, 8);
    color: white;
}

.awayTab.active {
    background-color: rgb(246, 184, 104)!important;
}

.alert-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
}

.hideAlert {
    display: none;
}

.showAlert {
    display: block;
}

.profile_image {
    height: 150px;
    width: 150px;
    border-radius: 100%;
    border: 5px #fff solid;
    box-shadow: 0px 0px 5px 0px #CCC;
}

.profile_image img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
}

.top_buttons_holder {
    text-align: right;
}

.nav-link.active {
    border-bottom: solid 4px #3fd000!important;
    border-top: solid 4px #3fd000!important;
}