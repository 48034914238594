#chat_holder {
  position: fixed;
  bottom: 0px;
  right: 230px;
  flex-direction: row;
  display: flex;
  z-index: 9999999999999;
}

/**** Chat Popup Layout******/

.msg_box {
  width: 350px;
  height: 450px;
  background:#fff;
  border-radius:5px 5px 0px 0px;
  -webkit-box-shadow: 0 0 10px 0 rgba(000,000,000,0.1);
  box-shadow: 0 0 10px 0 rgba(000,000,000,0.1);
  margin-left: 10px;
  position: relative;
}

.chat-image-holder {
  height: 40px;
  width: 45px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 5px;
}

.u_name span {
  display: block;
  margin-bottom: -8px;
}

.chat-image-holder img {
  width: 100%;
  height: auto;
  background-color: #FFF;
}

.u_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: middle;
}

.msg_head {
  color:#fff;
  padding:8px 10px;
  font-weight:400;
  cursor:pointer;
  border-radius:5px 5px 0px 0px;
  display: inline-flex;
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center; /* Aligns items vertically */
  justify-content: center;
  background: var(--primary-color);
}

.mediaCreditButton {
  background-color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.mediaCreditButton i {
  margin-right: 2px;
}

.msg_info_header {
  height: 40px;
  background-color: rgb(231, 159, 2);
  color: #FFFFFF;
  font-size: 12px;
  padding: 0px 5px;
  font-weight: 600;
  justify-content: space-between;
}

.msg_body_container {
  position: relative;
}

.emojipickerContainer {
  position: absolute;
  top: 0px;
}

.msg_body {
  background:#fff;
  height: 310px;
  font-size:12px;
  /*padding:15px 8px 60px;*/
  overflow:auto;
  overflow-x: hidden;
  border: 1px solid #dddddd;
}

.msg_input {
  width:100%;
  height: 100%;
  border: 1px solid #fff;
  border-top:1px solid #DDDDDD;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box;   
  box-sizing: border-box;  
}

.close-chatbox {
  padding: 5px 10px!important;
}

.msg_input:focus {
  outline: transparent!important;
  border: none!important;
  border-color: transparent!important;
  box-shadow: none!important;
}

.close {
  float:right;
  cursor:pointer;
  padding: 2px 0;
  color: #000 !important;
}

.close-white {
  float:right;
  cursor:pointer;
  padding: 2px 0;
  color: #FFF !important;
  }

.minimize {
  float:right;
  cursor:pointer;
  padding-right:5px;
  
}

.chat-timestamp {
  font-size: 10px;
}

.msg-top-date {
  text-align: center;
  color: gray;
  font-size: 10px;
}

.msg_push {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.msg-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

.msg-left {
  align-self: flex-start;
  background-color: #EEEEEE;
  padding: 10px;
  margin-right: auto;
  border-radius: 10px;
}

.btn-chat-media {
  font-size: 12px!important;
  padding: 5px!important;
}

.btn-chat-media i {
  margin-right: 2px;
}

.btn-msg-right {
  background-color: #FFF!important;
  color: #87194a!important;
}

.btn-msg-left {
  background-color: #87194a!important;
  color: #FFF!important;
}

.msg-right {
  align-self: flex-end;
  background-color: #87194a;
  color: #FFFFFF;
  padding: 10px;
  margin-left: auto;
  border-radius: 10px;
}
  
.msg_head .img-circle {
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.msg_footer {
  width: 100%;
  float: left;
  height: 50px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: #f8f8f8;
  display: flex;
}
.send-msg-btn {
  right: 0;
  display: inline-block;
  font-size: 16px;
  top: 0;
  padding: 8px 14px;
  line-height: 32px;
  color: #fff;
  background: var(--primary-color);
  cursor: pointer;
  z-index: 1;
}

.msg_footer input {
  width: 100%;
  float: left;
  line-height: 20px;
  font-size: 14px;
  padding: 8px 12px;
  font-weight: 400;
  border-radius: 0;
  height: auto;
  border: none;
  background: transparent;
  padding: 12px 44px 12px 10px;
}
.send-msg-btn {
  right: 0;
  display: inline-block;
  font-size: 16px;
  top: 0;
  padding: 8px 14px;
  line-height: 32px;
  color: #fff;
  background: var(--primary-color);
  /* background: "#333"; */
  cursor: pointer;
  z-index: 1;
}

.send-msg-btn-emoji {
  right: 0;
  display: inline-block;
  font-size: 16px;
  top: 0;
  padding: 8px 14px;
  line-height: 32px;
  color: #fff;
  background: black;
  cursor: pointer;
  z-index: 1;
}

@keyframes blink {
  0%, 100% {
    background-color: #fad776;
    color: #87194a;
  }
  50% {
    background-color: rgb(255, 121, 43);
    color: #000;
  }
}

.blink_tab {
  animation: blink 1s linear infinite;
}